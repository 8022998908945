import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from '@reach/router';

import { ROUTES } from '../../services/navigation/navigation.routes';
import {
  displayErrorNotification,
  displayInfoNotification
} from '../../services/notification-service/notification.service';
import { subscribeToNewsletter } from '../../services/newsletter-service/newsletter.service';

// import { links } from '../../links';
import { env } from '../../env';

import './Footer.scss';

function Footer() {
  const [email, setEmail] = useState('');
  const inputRef = useRef(null);
  const { t } = useTranslation();

  const onSubscribe = () => {
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

      if (emailRegex.test(email)) {
        subscribeToNewsletter(email)
          .then(() => {
            displayInfoNotification({
              duration: 3,
              message: t('LegalNotice.subscribeSuccessMsg', { email }),
              title: t('LegalNotice.subscribeSuccess')
            });
            setEmail('');
          })
          .catch(err => {
            console.log(err);
          });
      } else {
        displayErrorNotification({
          duration: 3,
          message: t('LegalNotice.usernameInvalid'),
          title: t('LegalNotice.invalidEntry')
        });
        inputRef.current.focus();
      }
    }
  };

  return (
    <div className='Footer'>
      <div className='FooterLinks'>
        {/* <a target='_blank' rel='noreferrer' href={links.twitter}>
          Follow us on instagram 
        </a>
        <a target='_blank' rel='noreferrer' href={links.instagram}>
          Follow us on twitter 
        </a> */}

        <Link to={ROUTES.help}>FAQ &amp; Support</Link>
        <Link to={ROUTES.termsOfUse}>Terms of use</Link>
        <Link to={ROUTES.privacyPolicy}>Privacy policy</Link>
      </div>

      <div className='NewsletterContainer'>
        <span>Stay updated with our newsletter</span>
        <div className='InputContainer'>
          <input
            name='email'
            autoComplete='off'
            ref={inputRef}
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder='Enter E-mail Address Here' />
          <button type='button' onClick={onSubscribe}>Subscribe</button>
        </div>
      </div>

      <div className='FooterText'>
        <div className='FT-Inner'>
          <p>All performers are at least 18 years of age.</p>
          <p>
            <Link to={ROUTES.compliance}>"18 U.S.C 2257 Record Keeping Requirements Compliance Statement"</Link>
          </p>
          <p>For sales support, visit our authorized sales agents:</p>

          <div className='ContentRemovalContainer'>
            <a href='mailto:support@adultpaysitesupport.com'>Billing support</a>|
            <a href='https://www.aebn.com/contentremoval' target='_blank' rel='noreferrer'>Content removal</a>
          </div>
          <div className='mb-1em'>For Epoch billing support click <a href="https://epoch.com" target="_blank" rel="noreferrer">HERE</a>.</div>
          <div className='mb-1em'>Copyright © {env.REACT_APP_SITE_NAME} All Rights Reserved.</div>

          <div>
            <a href='https://www.rtalabel.org' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/rta-logo.svg)' }}>rta logo</a>
            <a href='https://www.freespeechcoalition.com/' target='_blank' rel='noreferrer' className='FT-icon' style={{ backgroundImage: 'url(/img/fsc-logo.svg)' }}>fsc logo</a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer;